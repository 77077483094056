import React from 'react'
import './dumy.scss';
import { Link } from 'react-router-dom';
const DummyMobile = () => {
    const clickfunctionapple = () => {
        console.log("sdsdfsdsdfsdf")
        window.location.href = 'https://apps.apple.com/sg/app/book-better/id1644341922';
    }
    const clickfunctionandriod = () => {
        console.log("sdsdfsdsdfsdf")

        window.location.href = 'https://play.google.com/store/apps/details?id=com.bookbetter.zapdas';
    }
    return (
        <section className='FrontMain_Stores jsdsjhfsjdfhdsf slideshareaa mobiledesign_here_we_have'>
            <div className='container-main-selfss bwkbfewkjbfewijfkwjd'>
                <div className='row p-0 w-100'>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-12 p-0'>
                        <div className='leftsidemaindummy'>
                            <h3>
                                Download Our Mobile<br className='mmmmkkmm'></br>
                                App to Make it Easier
                            </h3>
                            {/* <h4>An easy to use service solution is coming on web soon. <br className='mmmmkkmm'></br> Meanwhile, please use the links below to use our Mobile apps.</h4> */}
                            <div className='ssscvsv'>
                                {/* <a className='ffffffsdfdsfdsffcfc' href='https://apps.apple.com/sg/app/book-better/id1644341922'> */}
                                <button className='asdasdasdasdasd jkjbjbhjbh' onClick={clickfunctionapple}><img src="\assets\dummy\Group (3).svg" alt="img" className="img-fluid jkjbjbhjbh ffffffffcfc" />
                                </button>
                                {/* </a> */}
                                {/* <a className='ffffffsdfdsfdsffcfc' href='https://play.google.com/store/apps/details?id=com.bookbetter.zapdas'> */}
                                <button className='asdasdasdasdasd sdhsdsdsdfdsf' onClick={clickfunctionandriod}>
                                    <img src="\assets\dummy\Group 30771.svg" alt="img" className="img-fluid ffffffffcfc sdhsdsdsdfdsf" />
                                </button>

                                {/* </a> */}
                            </div>
                            <h5 className='kasjdasjdasdasd'><Link to='/terms&conditon'>Terms of service</Link> & <Link to='/privacypolicy' >Privacy Policy</Link></h5>

                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-12 p-0'>
                        <div className='rightsidemaindummy'>
                            <img src='\assets\dummy\Illustration.png' alt='img' className='img-fluid desktop' />
                            <img src='\assets\dummy\Frame 90.png' alt='img' className='img-fluid mobile' />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default DummyMobile